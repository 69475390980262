import axios from "axios";
import { defineStore } from "pinia";
import { useUserStore } from "./userStore";
import { MatchResult } from "@/composables/utils/util.composable";

//const pa = userPiniaActions()

export const useMessageStore = defineStore("message", {
  state: () => ({
    error: <MatchResult | string | null>null,
  }),
  getters: {},
  actions: {
    clearState() {
      this.error = null;
    },
    async sendContactMessage(email: string, subject: string, message: string) {
      const userStore = useUserStore();
      let name = userStore.email;
      if (userStore.displayName) {
        name = userStore.displayName; // => TODO blank on reg signup
      }
      const uid = userStore.getUID;
      await userStore.fetchToken();
      const token = userStore.getToken;
      const cloudFunctionUrl =
        import.meta.env.VITE_ON_EMAIL;

      await axios
        .post(
          cloudFunctionUrl,
          {
            email: email,
            subject: subject,
            message: message,
            uid: uid,
            name: name,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((_response) => {
          // Handle the success response
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
        });
    },
  },
});
