<script setup lang="ts">
import { useLoadingStore } from "@/store/loadingStore";
import { ref, computed } from "vue";

const loadingStore = useLoadingStore();
const { isLoading } = useLoadingStore();

// Define a prop to receive the instanceName from the parent component
const props = defineProps(["instanceName", "size"]);

// Reactive variable to store the loading state
const loadingState = ref(false);
const indicatorSize = ref(props.size);

const computedLoading = computed(() => {
  loadingState.value = isLoading(props.instanceName);
  return loadingState.value;
});
</script>

<template>
  <div class="text-center">
    <v-progress-circular
      v-show="computedLoading"
      indeterminate
      model-value="20"
      :size="indicatorSize"
      color="#581010"
    >
      <v-card-text>
        {{ loadingStore.msg }}
      </v-card-text>
    </v-progress-circular>
  </div>
</template>
