import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  applyActionCode,
  UserCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset
} from "firebase/auth";
import axios from "axios";
import { Ref, ref } from "vue";
import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";

import { CustomErr, createFBCustomErrorHelper } from "../utils/error.composable";
import {
  OK,
  SuccessType,
  TokenType,
} from "@/composables/utils/util.composable";

// This module should be to only return firbase auth material
// avoid importing other composables -> composable import in api modules

export const localUser: Ref<User | null> = ref(null);

export const useAuth = () => {
  const auth = getAuth();

  let authStateResolved = false;
  let resolveAuthStatePromise: () => void;

  const authStatePromise = new Promise<void>((resolve) => {
    resolveAuthStatePromise = resolve;
  });

  const watchAuthState = (onUserChanged = (_user: User | null) => {}) => {
    const unwatchAuthState = onAuthStateChanged(auth, (user) => {
      if (!authStateResolved) {
        resolveAuthStatePromise();
        authStateResolved = true;
      }
      onUserChanged(user);
    });

    return unwatchAuthState;
  };

  const logoutGoogle = async (): Promise<void> => {
    // TODO: tryCatch if void return OK
    await signOut(auth);
  };

  // In userAuth.api *****************

  const sendEmailVerificationOnNewAccountTE = (
    user: User,
  ): TE.TaskEither<CustomErr.VerifyEmailError, SuccessType> => {
    return pipe(
      TE.tryCatch(
        () => sendEmailVerification(user),
        (error) =>
          createFBCustomErrorHelper(
            CustomErr.VerifyEmailError,
            `Some error mesg: ${user.email}`,
            error,
            "sendEmailOnNewAccountTE",
          ),
      ),
      TE.map(() => OK),
    );
  };

  const resetPasswordTE = (code: string, pw: string):
    TE.TaskEither<CustomErr.PasswordResetError, SuccessType> => {
      const r = pipe(
        TE.tryCatch(
          () => confirmPasswordReset(auth, code, pw),
          (error) =>
            createFBCustomErrorHelper(
              CustomErr.PasswordResetError,
              `Some error mesg:`,
              error,
              "resetPasswordTE",
            ),
        ),
        TE.map(() => OK)
      );
    return r;
  }

  const verifyPasswordCodeTE = (code: string):
    TE.TaskEither<CustomErr.PasswordResetError, SuccessType> => {
      const r = pipe(
        TE.tryCatch(
          () => verifyPasswordResetCode(auth, code),
          (error) =>
          createFBCustomErrorHelper(
            CustomErr.PasswordResetError,
            `Error resetting password.`,
            error,
            "verifyPasswordCodeTE",
          ),
        ),
        TE.map(() => OK)
      )
      return r;
  };

  const resetPasswordEmailTE = (email: string):
    TE.TaskEither<CustomErr.PasswordResetError, SuccessType> => {
      const r = pipe(
        TE.tryCatch(
          () => sendPasswordResetEmail(auth, email),
          (error) =>
            createFBCustomErrorHelper(
              CustomErr.PasswordResetError,
              `Error sending passord reset link to ${email}`,
              error,
              "resetPasswordEmail",
            ),
        ), TE.map(() => OK)
      )
      return r;
  }

  const createUserWithEmailPasswordWTE = (
    email: string,
    password: string,
  ): TE.TaskEither<
    CustomErr.CreateUserWithEmailAndPasswordError,
    UserCredential
  > => {
    const r = TE.tryCatch(
      () => createUserWithEmailAndPassword(auth, email, password),
      (error) =>
        createFBCustomErrorHelper(
          CustomErr.CreateUserWithEmailAndPasswordError,
          `Error fetching document snapshot: ${email}`,
          error,
          "createUserWithEmailPasswordWrapped",
        ),
    );
    return r;
  };

  const loginUserPassW = (
    email: string,
    password: string,
  ): TE.TaskEither<CustomErr.LoginUserPassError, User> => {
    const r = pipe(
      TE.tryCatch(
        () => signInWithEmailAndPassword(auth, email, password),
        (error) =>
          createFBCustomErrorHelper(
            CustomErr.LoginUserPassError,
            `CUSTOMERROR: `,
            error,
            "loginUserPass",
          ),
      ),
      TE.map((credential) => credential.user),
    );
    return r;
  };

  const loginWithGoogleTE = (): TE.TaskEither<
    CustomErr.GoogleFederatedLoginError,
    User
  > => {
    const r = pipe(
      TE.tryCatch(
        () => signInWithPopup(auth, new GoogleAuthProvider()),
        (error) =>
          createFBCustomErrorHelper(
            CustomErr.GoogleFederatedLoginError,
            `CUSTOMERROR: `,
            error,
            "loginWithGoogle",
          ),
      ),
      TE.map((cred: UserCredential) => cred.user),
    );
    return r;
  };

  const getTokenTE = (): TE.TaskEither<CustomErr.GetTokenError, TokenType> => {
    return pipe(
      TE.fromEither(getAuthenticatedUser()), // Convert Either from getAuthenticatedUser to TaskEither
      TE.flatMap((user) =>
        TE.tryCatch(
          () => user.getIdToken(true), // Use the user from the flatMap scope
          (error: any) =>
            createFBCustomErrorHelper(
              CustomErr.GetTokenError,
              "Error getting token",
              error,
              "getToken",
            ),
        ),
      ),
    );
  };

  const callOnEmailVerificationTE = (
    token: TokenType,
  ): TE.TaskEither<CustomErr.HTTPResponseError, SuccessType> => {
    const cloudFunctionUrl = import.meta.env.VITE_ON_EMAIL_VERIFICATION;

    return pipe(
      TE.tryCatch(
        () =>
          axios.post(cloudFunctionUrl, null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        (error) =>
          createFBCustomErrorHelper(
            CustomErr.HTTPResponseError,
            "Error calling onEmailVerification",
            error,
            "callOnEmailVerification",
          ),
      ),
      TE.chain((response) =>
        response.status === 200
          ? TE.right(OK)
          : TE.left(
              createFBCustomErrorHelper(
                CustomErr.HTTPResponseError,
                `Error response from server: ${response.status}`,
                new Error(`Server responded with status: ${response.status}`),
                "callOnEmailVerification",
              ),
            ),
      ),
    );
  };

  /**
   * @description - Helper: transforms User|null to option.
   * Composable for getUserFromNull
   * @param user
   * @returns
   */
  const userNullToOptionTE = (
    user: User | null,
  ): E.Either<CustomErr.UserNullToOptionError, O.Option<User>> => {
    const currentUserOption = O.fromNullable(auth.currentUser);
    const userOption = O.fromNullable(user);

    return E.fromPredicate(
      (userOpt: O.Option<User>) =>
        O.isSome(currentUserOption) &&
        O.isSome(userOpt) &&
        currentUserOption.value.uid === userOpt.value.uid,
      (error) =>
        createFBCustomErrorHelper(
          CustomErr.UserNullToOptionError,
          `CUSTOMERROR: ${user?.email ?? "Unknown user"}`,
          error,
          "getUserOption",
        ),
    )(userOption);
  };

  const isEmailVerifiedTE = (
    user: User,
  ): TE.TaskEither<CustomErr.IsEmailVerifiedError, boolean> => {
    return user.emailVerified
      ? TE.right(true)
      : TE.left(
          createFBCustomErrorHelper(
            CustomErr.IsEmailVerifiedError,
            "Email is not verified",
            null,
            "isEmailVerified",
          ),
        );
  };

  const isEmailVerifiedFromAuth = (): TE.TaskEither<
    | CustomErr.GetCurrentUserError
    | CustomErr.UserNotLoggedIn
    | CustomErr.IsEmailVerifiedError,
    boolean
  > => {
    const r = pipe(
      TE.fromEither(getAuthenticatedUser()), // Lift the Either from getAuthenticatedUser into a TaskEither
      TE.flatMap(
        (user) => isEmailVerifiedTE(user), // Use chain to continue with isEmailVerifiedTE if the user is fetched successfully
      ),
    );
    return r;
  };

  const reloadCurrentUserTE = (): TE.TaskEither<
    | CustomErr.GetCurrentUserError
    | CustomErr.UserNotLoggedIn
    | CustomErr.ReloadUserError,
    User
  > => {
    return pipe(
      getAuthenticatedUser(),
      TE.fromEither, // Convert Either to TaskEither
      TE.chain((user) =>
        TE.tryCatch(
          () => user.reload().then(() => user),
          (error) =>
            createFBCustomErrorHelper(
              CustomErr.ReloadUserError,
              "Error reloading current user",
              error,
              "reloadCurrentUserTE",
            ),
        ),
      ),
    );
  };

  const applyActionCodeWTE = (
    code: string,
  ): TE.TaskEither<CustomErr.ApplyActionCodeError, SuccessType> => {
    return pipe(
      TE.tryCatch(
        () => applyActionCode(auth, code),
        (error) =>
          createFBCustomErrorHelper(
            CustomErr.ApplyActionCodeError,
            "An Error occurred validating email action-code.",
            error,
            "userAuth.api.applyActionCodeWTE",
          ),
      ),
      TE.map(() => OK),
    );
  };

  const _getAuthenticatedUserOption = (): E.Either<
    CustomErr.GetCurrentUserError,
    O.Option<User>
  > => {
    return E.tryCatch(
      () => O.fromNullable(auth.currentUser),
      (error) =>
        createFBCustomErrorHelper(
          CustomErr.GetCurrentUserError,
          "Error calling Firebase auth.currentUser",
          error,
          "getAuthenticatedUserOption",
        ),
    );
  };

  const getAuthenticatedUser = (): E.Either<
    CustomErr.GetCurrentUserError | CustomErr.UserNotLoggedIn,
    User
  > => {
    return pipe(
      _getAuthenticatedUserOption(),
      E.chain(
        O.fold(
          () =>
            E.left(
              createFBCustomErrorHelper(
                CustomErr.UserNotLoggedIn,
                "User not logged in: auth.currentUser => null",
                null,
                "UserNotLoggedIn",
              ),
            ),
          (user) => E.right(user),
        ),
      ),
    );
  };

  const getAuthUserSimple = () => {
    return auth.currentUser;
  };

  return {
    resetPasswordTE,
    verifyPasswordCodeTE,
    resetPasswordEmailTE,
    logoutGoogle,
    authStatePromise,
    applyActionCodeWTE,
    reloadCurrentUserTE,
    userNullToOptionTE,
    isEmailVerifiedTE,
    isEmailVerifiedFromAuth,
    callOnEmailVerificationTE,
    getTokenTE,
    getAuthenticatedUser,
    getAuthUserSimple,
    watchAuthState,
    loginWithGoogleTE,
    createUserWithEmailPasswordWTE,
    sendEmailVerificationOnNewAccountTE,
    loginUserPassW,
  };
};

// NOT USING
/*
  const userFromNullishOrError = (user: User | null):
    TE.TaskEither<CustomErr.UserNotFoundError, User> => {

      return pipe(
        userNullToOption(user),
        E.fold(
          () => TE.left(
            new CustomErr.UserNotFoundError("User not found",
            new Error("No user"), 
            "getUserOrError")),
          (userOpt) => O.isNone(userOpt) 
            ? TE.left(
              new CustomErr.UserNotFoundError("User not found",
              null,
              "getUserOrError")) 
            : TE.right(userOpt.value)));
    };
  
  const isUserReturnedAuthenticated = (user: User | null):
    E.Either<CustomErr.IsAuthenticatedError, boolean> => {
    
      return pipe(
        userNullToOption(user),
        E.map(optionUser => O.isSome(optionUser)) // Map the Option<User> to boolean
      );
  };
  */
