<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Send Reset Password Link</v-card-title>
      <v-card-text>
        <v-text-field v-model="email" label="Email" outlined dense></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" @click="sendResetPasswordEmail">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
  <!-- Slot for custom content that triggers dialog -->
  <span @click="openDialog">
    <slot>Forgot login password?</slot>
  </span>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useUserStore } from "@/store/userStore";

const email = ref("");
const dialog = ref(false);
const userStore = useUserStore();

function sendResetPasswordEmail() {
  userStore.resetPasswordEmail(email.value);
  dialog.value = false;
}

function openDialog() {
  dialog.value = true;
}
</script>

