<script setup lang="ts">
import { useForm, useIsFieldValid } from "vee-validate";
import { toTypedSchema } from "@vee-validate/yup";
import * as yup from "yup";
import { watch, toRefs } from "vue";
import { useUserStore } from "@/store/userStore";
import { useAlertStore } from "@/store/alertStore";
import { useMessageStore } from "@/store/messageStore";

const msgStore = useMessageStore();
const userStore = useUserStore();
const alertStore = useAlertStore();

const { email: userEmail } = toRefs(userStore);

type InitialValues = {
  email: string;
  subject: string;
  message: string;
};

const initialValues: InitialValues = {
  email: userEmail.value || "",
  subject: "",
  message: "",
};

const schema = toTypedSchema(
  yup.object({
    email: yup.string().required().email(),
    message: yup.string().required().max(255),
    subject: yup.string(),
  }),
);

const {
  // meta,
  errors,
  values,
  defineField,
  setErrors,
  setFieldValue,
  validate,
} = useForm({
  validationSchema: schema,
  initialValues: initialValues,
});

const [email, emailAttrs] = defineField("email");
const [subject, subjectAttrs] = defineField("subject");
const [message, messageAttrs] = defineField("message");
const emailIsValid = useIsFieldValid("email");

watch(userEmail, async (newEmail) => {
  setFieldValue("email", newEmail || "");
  setErrors({ email: [] });
});

/*
const handleSubmit1 = async () => {
  // console.log("form values: ", JSON.stringify(values, null, 2));
  if (typeof values.email === "string") {
    if (typeof values.message == "string") {
      if (typeof values.subject == "string") {
        await fs.sendContactMessage(
        values.email,
        values.subject,
        values.message
      );
      alertStore.snackSuccess("Message sent, thank you.");
    }
      }
  }
};
*/

const handleSubmit = async () => {
  // Assuming validate() is a function that validates the form based on your schema
  const isValid = await validate(); // Triggers form validation
  if (!isValid) {
    console.error("Form is not valid.");
    return; // Stop submission if the form is invalid
  }

  // Use a combined condition to check all required fields at once
  if (
    typeof values.email === "string" &&
    typeof values.message === "string" &&
    typeof values.subject === "string"
  ) {
    //await fs.sendContactMessage(values.email, values.subject, values.message);
    await msgStore.sendContactMessage(
      values.email,
      values.subject,
      values.message,
    );
    alertStore.snackSuccess("Message sent, thank you.");
  } else {
    // Handle the case where one or more fields are not strings
    console.error("Form submission failed due to incorrect field types.");
  }
};
</script>

<template>
  <v-card
    width="350"
    class="mx-auto px-6 py-8 fav-blue"
    title="Contact us"
    outlined
  >
    <v-form @submit.prevent="handleSubmit" :validation-schema="schema">
      <v-text-field
        v-model="email"
        v-bind="emailAttrs"
        :error-messages="errors.email"
        type="text"
        label="From email"
        variant="outlined"
      />
      <v-text-field
        v-model="subject"
        v-bind="subjectAttrs"
        :error-messages="errors.subject"
        type="text"
        label="Subject"
        variant="outlined"
      />
      <v-textarea
        clearable
        clear-icon="mdi-close-circle"
        label="Text"
        v-model="message"
        v-bind="messageAttrs"
        :error-messages="errors.message"
        variant="outlined"
      ></v-textarea>
      <v-btn
        v-model="message"
        v-bind="messageAttrs"
        :error-messages="errors.message"
        class="me-4"
        type="submit"
        :disabled="!emailIsValid"
      >
        send
      </v-btn>
    </v-form>
  </v-card>
</template>
<style>
.fav-blue {
  color: #002f40;
}
</style>
