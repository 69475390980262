<script setup lang="ts">
import Alerts from "@/components/Alerts.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { ref, onBeforeMount, onMounted, onUnmounted } from "vue";
import { useLoadingStore } from "@/store/loadingStore";
import { useUserStore } from "@/store/userStore";

const { startLoading, stopLoading } = useLoadingStore();

let assetsLoaded = ref(false);

const userStore = useUserStore();

onUnmounted(() => {
  userStore.unlistenToAuthChanges();
});

onMounted(() => {
  // userStore.listenToAuthChanges(); in main.ts for now
  const userStore = useUserStore();
  userStore.listenToAuthChanges();

  window.addEventListener("load", () => {
    // All assets are loaded
    assetsLoaded.value = true;
    stopLoading("mainApp");
  });
});

onBeforeMount(() => {
  startLoading("mainApp");
});
</script>

<template>
  <v-app>
    <v-container>
      <Alerts />
      <!-- Content for small and larger screens (tablet, laptop, desktop) -->
      <v-row v-if="assetsLoaded" class="d-sm-flex">
        <v-col>
          <!-- Your content for small and larger screens goes here -->
          <router-view></router-view>
          <v-footer> © 2024 BC Cranberry Research Farm </v-footer>
        </v-col>
      </v-row>
      <div v-else>
        <LoadingIndicator instance-name="mainApp" size="80" />
      </div>
    </v-container>
  </v-app>
</template>

<style module>
.wrapper {
  padding: 20px;
}
</style>
