<template>
  <v-container fluid>
    <!--          :error-messages="errors"
          :loading="getSTBPALoading" -->
    <v-autocomplete
      v-model="computedVarietySelected"
      :items="computedVarietyOptions"
      @update:model-value="onChangeVariety"
      label="Variety"
      item-color="#dbecee"
      base-color="#581010"
      bg-color="#ffeded"
      single-line
      hint="Variety"
      persistent-hint
      persistent-placeholder
      placeholder="test"
      auto-select-first
      density="compact"
      eager
      :loading="false"
      no-data-text="Loading"
      variant="solo"
      style="max-width: 300px"
    >
    </v-autocomplete>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { useBPAStore } from "@/store/bpaStore";

const bpa = useBPAStore();
const { getAllVarieties, getVarietySelected } = storeToRefs(bpa);

const computedVarietyOptions = computed(() => {
  const varieties = getAllVarieties;
  return varieties.value.sort();
});

const computedVarietySelected = computed({
  get: function () {
    return getVarietySelected.value;
  },
  set: function (variety) {
    bpa.setVarietySelected(variety);
  },
});

const onChangeVariety = async (variety: string) => {
  //bpa.fetchDocsByVarietyFromFs(variety)
  bpa.updateOnSelect(variety);
  //bpa.fetchDocsByVariety(variety);
};
</script>
