import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordRaw,
} from "vue-router";
import UserAccessView from "@/views/UserAccessView.vue";
import UserMgmt from "@/views/UserMgmt.vue";
import UserHome from "@/views/UserHome.vue";
import { useUserStore } from "@/store/userStore";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Root",
    component: UserAccessView,
  },
  {
    path: "/login",
    name: "Login",
    component: UserAccessView,
  },
  {
    //path: "/users/:id",
    path: "/user", // moved away from RESTful std. to not expose uid
    name: "User",
    component: UserHome,
    beforeEnter: async (
      _to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      const userStore = useUserStore();

      await userStore.waitForAuthReady();
      const uidOrNull = userStore.getUID;

      if (uidOrNull === null) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },

  // Verifies email action code
  {
    path: "/usermgmt",
    name: "UserMgmt",
    component: UserMgmt,
  },

  // Catch-all route
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
