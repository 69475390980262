import { initializeApp, FirebaseApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import { getAuth, Auth } from "firebase/auth";
import { firebaseConfig } from "./config";

// Initialize Firebase App
const app: FirebaseApp = initializeApp(firebaseConfig);

// Export Firestore and Auth as singletons
export const db: Firestore = getFirestore(app);
export const auth: Auth = getAuth(app);
