// THIS SHOULD REPLACE auth.type.ts in auth/

import { FirebaseError } from "firebase/app"

export class FBCustomError extends Error {
  public originalError: unknown; // Error | FirebaseError | null;
  public sourceFunction: string;

  constructor(
    message: string,
    originalError: unknown, // Change to unknown
    sourceFunction: string = "UnknownFunction",
  ) {
    super(message);

    // Capture the stack trace, excluding the constructor call from it.
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.name = this.constructor.name;

    // Check and assign the original error
    if (
      originalError instanceof Error ||
      originalError instanceof FirebaseError
    ) {
      this.originalError = originalError;
    } else {
      // If the original error is not an Error or FirebaseError instance, it's set to null
      this.originalError = null;
      if (originalError !== null && originalError !== undefined) {
        console.warn("Non-Error type thrown:", originalError);
      }
    }

    this.sourceFunction = sourceFunction;
  }

  isFirebaseErrorOriginal(): this is FBCustomError & { originalError: FirebaseError } {
    //const v = this.originalError instanceof FirebaseError;
    return this.originalError instanceof FirebaseError;
  }

  isFirebaseError(): this is FBCustomError & { originalError: FirebaseError } {
    const v = this.originalError instanceof FirebaseError;
    console.log("V: ", v);
    return v;
  }

}

/*
export type UserMetaData = {
  claims: {
    admin: boolean,
    requesting: boolean
  };  
}
*/

// todo: should be number

export type ResponsePayload = any; // Define more specifically

export namespace CustomErr {
  export class SnapshotDoesNotExist extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "SnapshotDoesNotExist";
    }
  }

  export class VerifyPasswordCodeError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "VerifyPasswordCodeError";
    }
  }

  export class PasswordResetEmailError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "PasswordResetEmailError";
    }
  }

  export class PasswordResetError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "PasswordResetError";
    }
  }

  export class FetchRequestingError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "FetchRequestingError";
    }
  }

  export class GoogleFederatedLoginError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "GoogleFederatedLoginError";
    }
  }

  // TODO: ensure exceptions not raised/caught by firebase do not contain postfix Error
  export class ProcessSnapshotError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "ProcessSnapshotError";
    }
  }

  export class CreateUserWithEmailAndPasswordError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "CreateUserWithEmailAndPasswordError";
    }
  }

  export class UserNullToOptionError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "UserNullToOptionError";
    }
  }

  export class IsAuthenticatedError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "IsAuthenticatedError";
    }
  }

  export class GetCurrentUserError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "GetCurrentUserError";
    }
  }

  export class VerifyEmailError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "VerifyEmailError";
    }
  }

  export class FetchSnapshotError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "FetchSnapshotError";
    }
  }

  export class LoginUserPassError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "LoginUserPassError";
    }
  }

  export class IsEmailVerifiedError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "IsEmailVerifiedError";
    }
  }

  export class GetTokenError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "GetTokenError";
    }
  }
  export class HTTPResponseError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "HTTPResponseError";
    }
  }
  export class UserNotFoundError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "UserNotFoundError";
    }
  }

  export class EmailNotVerifiedError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "EmailNotVerifiedError";
    }
  }

  export class ApplyActionCodeError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "ApplyActionError";
    }
  }

  export class ReloadUserError extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "ApplyActionError";
    }
  }
  export class UserNotLoggedIn extends FBCustomError {
    constructor(
      message: string,
      originalError: unknown,
      sourceFunction: string,
    ) {
      super(message, originalError, sourceFunction);
      this.name = "UserNotLoggedIn";
    }
  }
}

export function createFBCustomErrorHelper1<T extends FBCustomError>(
  ErrorType: new (
    message: string,
    originalError: unknown,
    sourceFunction: string,
  ) => T,
  defaultMessage: string,
  originalError: unknown,
  sourceFunction: string,
): T {
  // Check if originalError is an instance of Error and format the message
  const errorMessage =
    originalError instanceof Error
      ? originalError.message
      : String(originalError);
  const fullMessage = `${defaultMessage}, Error Message: ${errorMessage}`;
  return new ErrorType(fullMessage, originalError, sourceFunction);
}

export function createFBCustomErrorHelper<T extends FBCustomError>(
  ErrorType: new (
    message: string,
    originalError: unknown,
    sourceFunction: string,
  ) => T,
  defaultMessage: string,
  originalError: unknown,
  sourceFunction: string,
): T {
  // Check if originalError is an instance of Error or FirebaseError and format the message
  let errorMessage = "";
  if (originalError != null) {
    if (originalError instanceof Error) {
      errorMessage = originalError.message;
    } else if (originalError instanceof FirebaseError) {
      errorMessage = originalError.message;
    } else {
      errorMessage = "No additional error information";
    }
  }
  const fullMessage = `${defaultMessage}, Error Message: ${errorMessage}`;
  return new ErrorType(fullMessage, originalError, sourceFunction);
}

/*
Factor function issues: 
The dynamic nature of the class created by createCustomErrorClass makes it difficult for TypeScript to infer the correct type.

function createCustomErrorClass(name: string) {
  return class extends FBCustomError {
    constructor(message: string, originalError: unknown, sourceFunction: string) {
      super(message, originalError, sourceFunction);
      this.name = name;
    }

    static create(message: string, originalError: unknown, sourceFunction: string) {
      return createFBCustomErrorHelper(this, message, originalError, sourceFunction);
    }
  };
}
export const CreateUserWithEmailAndPasswordError = createCustomErrorClass("CreateUserWithEmailAndPasswordError");
*/
