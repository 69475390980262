<script setup lang="ts">
import { ref } from "vue";
import GoogleLogin from "@/components/GoogleLogin/GoogleLogin.vue";
import ResetEmail from "@/components/GoogleLogin/ResetEmail.vue";
import { useAlertStore } from "@/store/alertStore";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/userStore";
import Alerts from "@/components/Alerts.vue";
import { isSuccessType } from "@/composables/utils/util.composable";

const router = useRouter();
const userStore = useUserStore();
const alertStore = useAlertStore();
const email = ref("");
const password = ref("");
const visible = ref();

// Watch signup status
let user = userStore.getUID; // ref(auth.user);

const Login = async () => {
  await userStore.loginUserPass(email.value, password.value);
  // Check for error and clear
  const uid = userStore.getUID;
  if (uid != null) {
    router.push({
      name: "User",
    });
  }
};

const Register = async () => {
  const result = await userStore.registerWithEmailVerification(
    email.value,
    password.value,
  );

  if (isSuccessType(result)) {
    alertStore.alertWarn(
      "You successfully registered! Please go to your Email to validate your account.",
      "regUserPass",
    );
    alertStore.snackSuccess("You successfully registered!");
    alertStore.snackWarn(
      "Please go to your email to validate your account.",
      2500,
    );
    return user;
  } else {
    // TODO: get error from store handle RESOLVED, ERR_JOB_PENDING
    console.error("There was an error creating account.");
  }
};
</script>
<template>
  <div>
    <v-card
      class="mx-auto pa-12 pb-8"
      elevation="8"
      max-width="448"
      rounded="lg"
    >
      <Alerts target="regUserPass">
        {{ alertStore.alertMsg }}
      </Alerts>
      <v-toolbar
        class="mb-0"
        color="#002f40"
        elevation="2"
        density="comfortable"
        rounded
      >
        <v-toolbar-title>Registration and Login</v-toolbar-title>
        <template v-slot:image>
          <v-img
            gradient="to bottom left, rgba(246,227,227), rgba(0,47,64,.7)"
          ></v-img>
        </template>
        <slot></slot>
      </v-toolbar>
      <v-card class="mb-4" color="surface-variant" variant="tonal">
        <v-card-text class="mb-1 text-medium-emphasis text-caption">
          Currently we are only providing site access to BC Cranberry Growers
          and Cranberry Researchers. Your account information will be
          automatically submitted into our approval system upon account
          registration. Please fill in the information required on the
          registration page.
        </v-card-text>
      </v-card>
      <div class="text-subtitle-1 text-medium-emphasis">Account</div>
      <v-text-field
        v-model="email"
        density="compact"
        placeholder="Email address"
        prepend-inner-icon="mdi-email-outline"
        variant="outlined"
      />

      <div
        class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
      >
        Password
        <ResetEmail>
          Forgot login password?
        </ResetEmail>
      </div>

      <v-text-field
        v-model="password"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        density="compact"
        placeholder="Enter your password"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append-inner="visible = !visible"
      />
      <v-btn
        @click="Login"
        flat
        block
        class="text-cran"
        color="#ffeded"
        size="large"
      >
        Log In
        <v-icon class="ml-2" icon="mdi-login"></v-icon>
      </v-btn>
      <v-btn @click="Register" border flat block class="text-center mb-8">
        <span class="text-cran">Sign Up</span>
        <v-icon
          class="ml-2 text-cran"
          icon="mdi-account-multiple-plus"
        ></v-icon>
      </v-btn>
      <GoogleLogin />
    </v-card>
  </div>
</template>
<style>
.text-cran {
  color: #581010;
}
</style>
