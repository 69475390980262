import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/function";
import * as E from "fp-ts/Either";
import { match, P } from "ts-pattern";

import { useMetaFs } from "@/composables/utils/fs.composable";
import { useBPAFs } from "@/composables/utils/fs.composable";
import { useAuth } from "@/composables/auth/auth.composable";
import {
  OK,
  ERR_JOB_PENDING,
  RESOLVED,
  formatter,
  MatchResult,
  errorToCloud,
  TokenType,
} from "@/composables/utils/util.composable";
import { CustomErr } from "@/composables/utils/error.composable";

// Api handling BE errors and translating fp-ts containers to
// raw data for pinia.

const metaFs = useMetaFs(); // move this to lazy
const bpa = useBPAFs(); // move this to lazy

export const bpaPiniaActions = () => {
  const fetchAllYears = async () => {
    const result = pipe(bpa.fetchAllYearsTE());

    const eitherResult = await result();

    return match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleFetchAllYearsErrors(leftResult),
      )
      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("BPA Data", OK, payload);
      })
      .exhaustive();
  };

  const handleFetchAllYearsErrors = (
    leftResult: E.Left<
      | CustomErr.FetchSnapshotError
      | CustomErr.ProcessSnapshotError
      | CustomErr.SnapshotDoesNotExist
    >,
  ) => {
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.FetchSnapshotError),
        (error: CustomErr.FetchSnapshotError) => {
          errorToCloud(error);

          return formatter("Error fetching AllYears snapshot.", RESOLVED, null);
        },
      )

      .otherwise(() => {
        errorToCloud(leftResult.left);

        return formatter("An error occurred fetching years.", RESOLVED, null);
      });
    return r;
  };

  const handleFetchAllVarietiesErrors = (
    leftResult: E.Left<
      | CustomErr.FetchSnapshotError
      | CustomErr.ProcessSnapshotError
      | CustomErr.SnapshotDoesNotExist
    >,
  ) => {
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.FetchSnapshotError),
        (error: CustomErr.FetchSnapshotError) => {
          errorToCloud(error);

          return formatter(
            "Error fetching AllVarieties snapshot.",
            RESOLVED,
            null,
          );
        },
      )

      .otherwise(() => {
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error while getting variety names.",
          RESOLVED,
          null,
        );
      });

    return r;
  };

  const fetchAllVarieties = async () => {
    const result = pipe(bpa.fetchAllVarietiesTE());

    const eitherResult = await result();

    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleFetchAllVarietiesErrors(leftResult),
      )

      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("BPA Data", OK, payload);
      })
      .exhaustive();
    return r;
  };

  const handleFetchByVarietyErrors = (
    leftResult: E.Left<CustomErr.FetchSnapshotError>,
  ) => {
    const result = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.FetchSnapshotError),
        (error: CustomErr.FetchSnapshotError) => {
          let result: MatchResult;
          console.log("error: ", error);
          console.log("leftResult.left: ", leftResult.left);
          if (error.isFirebaseError()) {
            console.log("isFirebseError")
            if (
              error.isFirebaseError() &&
              error.originalError.code === "permission-denied"
            ) {
              // Do something specific
              result = formatter("Permission Denied", ERR_JOB_PENDING, null);
            } else {
              result = formatter("Firebase Error", RESOLVED, null);
            }
          } else {
            result = formatter("Other Error", RESOLVED, null);
          }
          errorToCloud(error);
          return result;
        },
      )

      .otherwise(() => {
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error handling fetchByVariety.",
          RESOLVED,
          null,
        );
      });

    return result;
  };

  const fetchByVariety = async (variety: string) => {
    const result = pipe(bpa.fetchByVarietyTE(variety));

    const eitherResult = await result();
    console.log("fetchByVariety result: ", eitherResult);
    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleFetchByVarietyErrors(leftResult),
      )

      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("Authenticated User", OK, payload);
      })
      .exhaustive();
    return r;
  };

  return {
    fetchAllVarieties,
    fetchAllYears,
    fetchByVariety,
  };
};

export const userPiniaActions = () => {
  const authUtil = useAuth();

  const handleFederatedLoginErrors = (
    leftResult: E.Left<CustomErr.GoogleFederatedLoginError>,
  ) => {
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.GoogleFederatedLoginError),
        (error: CustomErr.GoogleFederatedLoginError) => {
          console.error(
            "CustomErr.GoogleFederatedLoginError occurred:",
            error.message,
          );
          return formatter("Error login in user", RESOLVED, null);
        },
      )

      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error while getting logging in user.",
          RESOLVED,
          null,
        );
      });
    return r;
  };

  const logout = async () => {
    await authUtil.logoutGoogle();
  };

  const handleResetEmailErrors = (
    leftResult: E.Left<CustomErr.PasswordResetEmailError>,
  ) => {
    ////////// TODO!!!!!!!!!!!!!
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.PasswordResetEmailError),
        (error: CustomErr.PasswordResetEmailError) => {
          console.error(
            "CustomErr.PasswordResetEmailError occurred:",
            error.message,
          );
          return formatter("Error emailing password reset link", RESOLVED, null);
        },
      )

      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error while getting logging in user.",
          RESOLVED,
          null,
        );
      });
    return r;
  };

  const handleVerifyPWResetCodeErrors = (
    leftResult: E.Left<CustomErr.PasswordResetEmailError>,
  ) => {
    ////////// TODO!!!!!!!!!!!!!
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.PasswordResetEmailError),
        (error: CustomErr.PasswordResetEmailError) => {
          console.error(
            "CustomErr.PasswordResetEmailError occurred:",
            error.message,
          );
          return formatter("Error emailing password reset link", RESOLVED, null);
        },
      )

      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error while getting logging in user.",
          RESOLVED,
          null,
        );
      });
    return r;
  };

  const handlePWResetErrors = (
    leftResult: E.Left<CustomErr.PasswordResetError>,
  ) => {
    ////////// TODO!!!!!!!!!!!!!
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.PasswordResetError),
        (error: CustomErr.PasswordResetError) => {
          console.error(
            "CustomErr.PasswordResetError occurred:",
            error.message,
          );
          return formatter("Error resetting password", RESOLVED, null);
        },
      )

      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error while getting logging in user.",
          RESOLVED,
          null,
        );
      });
    return r;
  };

  const passwordReset = async (code: string, pw: string) => {
    const result = pipe(
      authUtil.resetPasswordTE(code, pw),
      TE.map((success) => success)
    )
    const eitherResult = await result();
    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
      handlePWResetErrors(leftResult),
      )

      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("Password reset successful", OK, payload)
      })
      .exhaustive()
    return r;
  }

  const verifyPasswordCode = async (code: string) => {
    const result = pipe(
      authUtil.verifyPasswordCodeTE(code),
      TE.map((success) => success)
    )
    const eitherResult = await result();
    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
      handleVerifyPWResetCodeErrors(leftResult),
      )

      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("Password reset code validated", OK, payload)
      })
      .exhaustive()
    return r;
  }

  const sendPasswordResetEmail = async (email: string) => {
    const result = pipe(
      authUtil.resetPasswordEmailTE(email),
      TE.map((success) => success)
    )
    const eitherResult = await result();
    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleResetEmailErrors(leftResult),
      )

      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("Password reset email sent", OK, payload)
      })
      .exhaustive();
    return r;
  }

  const googleFederatedlogin = async () => {
    const result = pipe(
      authUtil.loginWithGoogleTE(),

      // Back-end creates User and user_meta docs
      TE.map((user) => user),
    );
    const eitherResult = await result();
    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleFederatedLoginErrors(leftResult),
      )

      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("Authenticated User", OK, payload);
      })
      .exhaustive();

    return r;
  };

  const handleUserPassLoginErrors = (
    leftResult: E.Left<CustomErr.GoogleFederatedLoginError>,
  ) => {
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.LoginUserPassError),
        (error: CustomErr.LoginUserPassError) => {
          console.error(
            "CustomErr.LoginUserPassError occurred:",
            error.message,
          );
          return formatter(
            "Error logging in user via uname and pass.",
            RESOLVED,
            null,
          );
        },
      )

      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error while logging in user.",
          RESOLVED,
          null,
        );
      });
    return r;
  };

  const loginUserPass = async (email: string, password: string) => {
    const result = pipe(
      authUtil.loginUserPassW(email, password),
      // other work?
    );
    const eitherResult = await result();
    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleUserPassLoginErrors(leftResult),
      )
      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("Authenticated User", OK, payload);
      })
      .exhaustive();
    return r;
  };

  const handleRequestingErrors = (
    leftResult: E.Left<
      | CustomErr.FetchSnapshotError
      | CustomErr.ProcessSnapshotError
      | CustomErr.SnapshotDoesNotExist
    >,
  ) => {
    const r = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.FetchRequestingError),
        (error: CustomErr.FetchRequestingError) => {
          console.error("CustomErr.FetchRequestingError occurred:", error);
          return formatter("Error fetching user metadata.", RESOLVED, null);
        },
      )

      .otherwise(() => {
        errorToCloud(leftResult.left);
        return formatter(
          "Unknown error while getting userMetada.",
          RESOLVED,
          null,
        );
      });
    return r;
  };

  const fetchRequestingW = async (uid: string) => {
    const result = pipe(
      // metaFs.fetchRequesting(uid)
      metaFs.fetchRequestingWithRetry(uid),
    );

    const eitherResult = await result();

    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleRequestingErrors(leftResult),
      )
      .with({ _tag: "Right" }, (rightResult) => {
        const payload = rightResult.right;
        return formatter("UserMeta", OK, payload);
      })
      .exhaustive();
    return r;
  };

  const handleRegisterErrors = (
    leftResult: E.Left<
      CustomErr.FetchSnapshotError | CustomErr.ProcessSnapshotError
    >,
  ) => {
    const r = match(leftResult.left)
      // TODO: auth/email-already-in-use
      // CreateUserWithEmailAndPasswordError: Error fetching document snapshot: daniel.r.dufau@gmail.com, Error Message: Firebase: Error (auth/email-already-in-use).
      .with(P.instanceOf(CustomErr.ProcessSnapshotError), (error) => {
        console.error("ProcessSnapshotError occurred:", error.message);
        return formatter("Snapshot processing failed", RESOLVED, null);
      })

      .with(
        P.instanceOf(CustomErr.FetchSnapshotError),
        (error: CustomErr.FetchSnapshotError) => {
          console.error("FetchSnapshotError occurred:", error.message);
          return formatter("Snapshot fetching data", RESOLVED, null);
        },
      )

      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        return formatter("Data could not be fetched", RESOLVED, null);
      });
    return r;
  };

  /**
   *
   * @param email
   * @param password
   * @returns void
   * @description
   * What occurs on the backend:
   * - BE: Firestore User document created
   * - BE: Firebase auth customClaims updated
   * - BE: Firestore user_metadata document created
   */
  const registerWithEmailVerificationTE = async (
    email: string,
    password: string,
  ) => {
    const result = pipe(
      authUtil.createUserWithEmailPasswordWTE(email, password),
      // Consider returning successes for each op on widening righ
      TE.map((credential) => credential.user),
      TE.chainW((user) =>
        pipe(
          authUtil.userNullToOptionTE(user),
          TE.fromEither,
          TE.map(() => user),
        ),
      ),
      TE.chainW((user) => authUtil.sendEmailVerificationOnNewAccountTE(user)),
      TE.mapLeft((error) => {
        console.error(error); // Handle error
        return error;
      }),
      TE.map((user) => {
        // Further processing with user
        return user;
      }),
    );

    const eitherResult = await result();

    const r = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) => handleRegisterErrors(leftResult))
      .with({ _tag: "Right" }, (rightResult) => {
        rightResult.right;
        return formatter("Authenticated User", OK, rightResult.right);
      })
      // NOTE: isRequesting, isVerify checked in HandleUserChange
      .exhaustive();
    return r;
  };

  /**
   * @description Note: helper. Returns Either because it is used in verifyEmail pipe,
   * this function lives here, however, because it is fb agnostic.
   * TODO: probably should not be called as "Public" mv outside of use..
   * @param code
   * @returns
   */
  const _applyActionCodeReloadUser = (code: string) => {
    const result = pipe(
      code,
      authUtil.applyActionCodeWTE,
      TE.flatMap(() => authUtil.reloadCurrentUserTE()),
      TE.map((user) => {
        return user;
      }),
    );
    return result;
  };

  const getToken = async () => {
    const result = pipe(authUtil.getTokenTE());

    const eitherResult = await result();

    const matchResult = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) => handleGetTokenErrors(leftResult))

      .with({ _tag: "Right" }, (rightResult) =>
        formatter(
          "Successfully fetched token.",
          OK,
          rightResult.right as TokenType,
        ),
      )

      .exhaustive();
    return matchResult;
  };

  const handleGetTokenErrors = (
    leftResult: E.Left<CustomErr.GetTokenError>,
  ) => {
    const result = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.GetTokenError),
        (error: CustomErr.GetTokenError) => {
          if (
            error.isFirebaseError() &&
            error.originalError.code === "SOMECODETODO"
          ) {
            return formatter("TODO", ERR_JOB_PENDING, null);
          }
          console.error("", error.message);
          return formatter("Error getting token.", RESOLVED, null);
        },
      )
      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        return formatter("Unknown error getting token.", RESOLVED, null);
      });
    return result;
  };

  const verifyEmailCodeTE = async (code: string) => {
    const result = pipe(
      _applyActionCodeReloadUser(code),
      TE.chainW(() =>
        pipe(
          authUtil.reloadCurrentUserTE(),
          TE.chain((user) => {
            // savedUser = user; // Update savedUser when we have the user object
            return pipe(
              authUtil.isEmailVerifiedTE(user),
              TE.chain((isVerified) =>
                isVerified
                  ? authUtil.getTokenTE() // authUtil.getTokenTE(user)
                  : TE.left(
                      new CustomErr.IsEmailVerifiedError(
                        "Email is not verified",
                        null,
                        "isEmailVerified",
                      ),
                    ),
              ),
              TE.chain((token) => authUtil.callOnEmailVerificationTE(token)),
            );
          }),
        ),
      ),
      TE.map(() => OK), // If all steps succeed, return OK
    );

    const eitherResult = await result();

    const matchResult = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) =>
        handleLeftEmailVerification(leftResult),
      )
      .with({ _tag: "Right" }, (rightResult) =>
        // rightResult.right // OK
        formatter("Email successfully verified.", OK, rightResult.right),
      )

      .exhaustive();

    return matchResult;
  };

  const isAuthUserVerified = async () => {
    const result = pipe(authUtil.isEmailVerifiedFromAuth());
    const eitherResult = await result();
    const matchResult = match(eitherResult)
      .with({ _tag: "Left" }, (leftResult) => {
        return handleLeftEmailVerification(leftResult);
      })
      .with({ _tag: "Right" }, (rightResult) => {
        return formatter("Email successfully verified.", OK, rightResult.right);
      })

      .exhaustive();

    return matchResult;
  };

  const handleLeftEmailVerification = (
    leftResult: E.Left<
      | CustomErr.GetCurrentUserError
      | CustomErr.UserNotLoggedIn
      | CustomErr.ReloadUserError
      | CustomErr.ApplyActionCodeError
      | CustomErr.HTTPResponseError
    >,
  ) => {
    const result = match(leftResult.left)
      .with(
        P.instanceOf(CustomErr.ApplyActionCodeError),
        (error: CustomErr.ApplyActionCodeError) => {
          // TODO: parse firebase exceptions => use state machine xstate
          if (
            error.isFirebaseError() &&
            error.originalError.code === "auth/expired-action-code"
          ) {
            return formatter(
              "Email verification code expired, we are sending a new verification code",
              ERR_JOB_PENDING,
              null,
            );
          }
          console.error("ApplyActionCodeError occurred:", error.message);
          return formatter("Error applying action code", RESOLVED, null);
        },
      )
      .otherwise(() => {
        console.error("An unknown error occurred:", leftResult.left.message);
        return formatter(
          "Unknown error handling email verification.",
          RESOLVED,
          null,
        );
      });
    return result;
  };

  return {
    passwordReset,
    verifyPasswordCode,
    sendPasswordResetEmail,
    logout,
    getToken,
    verifyEmailCodeTE,
    registerWithEmailVerificationTE,
    isAuthUserVerified,
    loginUserPass,
    fetchRequestingW,
    googleFederatedlogin,
  };
};
