<template>
  <v-card
    class="mx-auto pa-12 pb-8"
    elevation="8"
    max-width="448"
    min-height="500"
    rounded="lg"
  >
    <v-form
      v-model="form"
      @submit.prevent="handleSubmit"
    >
      <v-text-field
        v-model="password"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        density="compact"
        placeholder="Enter your password"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append-inner="visible = !visible"
      />
      <br>
      <v-btn
        :disabled="!form"
        :loading="loading"
        block
        color="success"
        size="large"
        type="submit"
        variant="elevated"
      >
        Submit
      </v-btn>      
    </v-form>
  </v-card>
</template>

<script setup lang="ts">
import { useUserStore } from "@/store/userStore";
import { ref, defineProps } from "vue";
import { useAlertStore } from "@/store/alertStore";
import { useRouter } from "vue-router";


const props = defineProps({
  oobCode: String
});

const router = useRouter();
const alertStore = useAlertStore();
const userStore = useUserStore();
const password = ref("");
const visible = ref();
const form = ref(false);
const loading = ref(false);

const handleSubmit = async (): Promise<void> => {
  if (!props.oobCode) return;
  try {
    const response = await userStore.resetPassword(props.oobCode, password.value);
    alertStore.snackWarn(response.message);
    router.push({ name: "Login" });
  } catch (e) {
    // Handle error
    router.push({ name: "Login" });

  }
};
</script>