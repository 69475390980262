<script setup lang="ts">
import RegUserPass from "@/components/GoogleLogin/RegUserPass.vue";
import ProfileMenu from "@/components/ProfileMenu.vue";
import AppBar from "@/components/AppBar.vue";
import AboutUs from "@/components/AboutUs.vue";
import { useUserStore } from "@/store/userStore";

const userStore = useUserStore();
</script>

<template>
  <AppBar>
    <v-toolbar-title class="title-element">
      British Columbia Cranberry Research Farm - <strong>Data</strong>
    </v-toolbar-title>
    <ProfileMenu v-if="userStore.getUID" />
  </AppBar>
  <div>
    <AboutUs />
  </div>
  <div>
    <RegUserPass />
  </div>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
.title-element {
  font-family: "Roboto";
  font-weight: normal; /* Medium weight */
  font-size: 1em;
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
}
</style>
