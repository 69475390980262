import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  DocumentSnapshot,
  Firestore,
  QuerySnapshot,
  WhereFilterOp,
} from "firebase/firestore";
import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/Either";
import { pipe } from "fp-ts/lib/function";

import { createFBCustomErrorHelper, CustomErr } from "./error.composable";
import { BPAType, newBPA, Claims, VarietiesData, YearsData } from "./util.composable";
import { db } from "@/services/firebase.service";

const _fetchDocsFromQueryHelper = (
  db: Firestore,
  cName: string,
  condition: [string, WhereFilterOp, any],
): TE.TaskEither<CustomErr.FetchSnapshotError, QuerySnapshot> => {
  // Build the query here so that query params can be logged in exceptions
  const [field, operator, value] = condition;
  const q = query(collection(db, cName), where(field, operator, value));
  const description = `Collection: ${cName}, Condition: ${field} ${operator} ${JSON.stringify(value)}`;

  return TE.tryCatch(
    () => getDocs(q),
    (error) =>
      createFBCustomErrorHelper(
        CustomErr.FetchSnapshotError,
        `Error: executing query: ${description}`,
        error,
        "_fetchDocsFromQueryHelper",
      ),
  );
};

const _retryGetDocHelper = (
  dName: string,
  cName: string,
  retries = 6,
  delay = 1000,
): TE.TaskEither<
  CustomErr.FetchSnapshotError,
  DocumentSnapshot<DocumentData, DocumentData>
> => {
  const r = pipe(
    TE.tryCatch(
      () =>
        new Promise<DocumentSnapshot<DocumentData>>((resolve, reject) => {
          const attemptFetch = (attempt: number) => {
            _getDocHelper(dName, cName)().then((result) => {
              pipe(
                result,
                E.fold(
                  (error) => reject(error),
                  (docSnapshot) => {
                    if (!docSnapshot.exists() && attempt < retries) {
                      //console.log("RETRYING..", retries);
                      setTimeout(() => attemptFetch(attempt + 1), delay);
                    } else if (!docSnapshot.exists()) {
                      reject(
                        createFBCustomErrorHelper(
                          CustomErr.SnapshotDoesNotExist,
                          `Document does not exist after ${retries} attempts: ${dName}`,
                          undefined,
                          "retryGetDocHelper",
                        ),
                      );
                    } else {
                      resolve(docSnapshot);
                    }
                  },
                ),
              );
            });
          };
          attemptFetch(0);
        }),
      (error) =>
        createFBCustomErrorHelper(
          CustomErr.FetchSnapshotError,
          `Error: collection: ${cName}, DB: ${dName}`,
          error,
          "_retryGetDocHelper",
        ),
    ),
  );
  return r;
};

const _getDocHelper = (dName: string, cName: string) => {
  const cRef = collection(db, cName);
  const dRef = doc(cRef, dName);

  const result = TE.tryCatch(
    () => getDoc(dRef),
    (error) =>
      createFBCustomErrorHelper(
        CustomErr.FetchSnapshotError,
        `Error: Collection: ${cName}, DocName: ${dName}`,
        error,
        "_getDocHelper",
      ),
  );
  return result;
};


const _getSnapshotHelper = (
  docSnapshot: DocumentSnapshot<DocumentData>,
): E.Either<
  CustomErr.ProcessSnapshotError | CustomErr.SnapshotDoesNotExist,
  DocumentData
> => {
  return pipe(
    E.tryCatch(
      () => docSnapshot.data(),
      (error): CustomErr.ProcessSnapshotError =>
        createFBCustomErrorHelper(
          CustomErr.ProcessSnapshotError,
          "Error retrieving field from snapshot 'docSnapshot.data()'",
          error,
          "_getSnapshotHelper",
        ),
    ),
    E.flatMap(
      E.fromPredicate(
        (data): data is DocumentData => data !== undefined,
        (error): CustomErr.SnapshotDoesNotExist =>
          createFBCustomErrorHelper(
            CustomErr.SnapshotDoesNotExist,
            "Document snapshot does not exist",
            error,
            "_getSnapshotHelper",
          ),
      ),
    ),
  );
};

export const useBPAFs = () => {

  const fetchAllYearsTE = (
    collectionName = "years",
    docName = "uniqueYears",
  ) => {
    return pipe(
      _getDocHelper(docName, collectionName),
      TE.flatMap((docSnapshot) =>
        TE.fromEither(_getSnapshotHelper(docSnapshot)),
      ),
      TE.map((docData): YearsData => {
        return docData as YearsData;
      }),
    );
  };

  // Modify the function to accept _getDocHelper as a parameter with a default value
  const fetchAllVarietiesTE = (
    collectionName = "varieties",
    docName = "uniqueVarieties",
  ) => {

    return pipe(
      _getDocHelper(docName, collectionName),
      TE.flatMap((docSnapshot) =>
        TE.fromEither(_getSnapshotHelper(docSnapshot)),
      ),
      TE.map((docData): VarietiesData => {
        return docData as VarietiesData;
      }),
    );
  };

  const fetchByVarietyTE = (
    variety = "",
    collectionName = "barrels_per_acre",
  ) => {
    console.log("Variety: ", variety);
    const r = pipe(
      _fetchDocsFromQueryHelper(db, collectionName, ["variety", "==", variety]),
      TE.map((querySnapshot) => {
        const data: BPAType[] = [];
        querySnapshot.forEach((doc) => {
          const document = doc.data();
          const bpaDoc = newBPA(
            document.sample_count,
            document.sdc3_barrels_avg,
            document.total_barrels_avg,
            document.date_collected,
            document.variety
          )

          data.push(bpaDoc as BPAType);
        });

        return data;
      }),
    );
    //console.log("fetchByVarietyTE: ", r);
    return r;
  };

  return {
    fetchByVarietyTE,
    fetchAllVarietiesTE,
    fetchAllYearsTE,
  };
};

export const useMetaFs = () => {
  const fetchRequestingWithRetry = (
    uid: string,
    collectionName = "user_metadata",
    retries = 6,
    delay = 1000,
  ) => {
    return pipe(
      _retryGetDocHelper(uid, collectionName, retries, delay),
      TE.flatMap((docSnapshot) =>
        TE.fromEither(_getSnapshotHelper(docSnapshot)),
      ),
      TE.map((d) => d as Claims),
      TE.mapLeft((error) => error),
    );
  };

  const fetchRequesting = (
    // NOT USING CURRENTLY!
    uid: string,
    collectionName = "user_metadata",
  ) => {
    const result = pipe(
      _getDocHelper(uid, collectionName),
      TE.flatMap((docSnapshot) =>
        TE.fromEither(_getSnapshotHelper(docSnapshot)),
      ),
      // TE.map((d): UserMetaData => {
      //  return d as UserMetaData;
      // }),
      TE.map((d): Claims => {
        return d as Claims;
      }),
      TE.mapLeft((error) => {
        return error;
      }),
    );
    return result;
  };
  return {
    fetchRequestingWithRetry,
    fetchRequesting,
  };
};

export const useFs = () => {
  const sendContactMessage = async (
    _email: string,
    _subject: string,
    _message: string,
  ) => {
    /*
    const userStore = useUserStore();
    let name = userStore.email
    if (userStore.raw.hasOwnProperty("displayName")) {
      name = userStore.raw["displayName"]; // => TODO blank on reg signup
    }
    const uid = userStore.raw.uid;
    const token = userStore.token;
    const cloudFunctionUrl =
      "https://us-central1-bccrf-dev.cloudfunctions.net/onEmail";

    axios
      .post(
        cloudFunctionUrl,
        {
          email: email,
          subject: subject,
          message: message,
          uid: uid,
          name: name
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        },
      )
      .then((response) => {
        // Handle the success response
        // console.log(response.data);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
      */
  };
  return {
    sendContactMessage,
    //getUserData,
    // getIsRequesting,
  };
};
