<template>
  <v-container>
    <v-tabs color="#812d2a" v-model="activeTab">
      <v-tab v-for="(tab, index) in computedTabItems" :key="index">
        <div>{{ tab.label }}</div>
      </v-tab>
      <LoadingIndicator instance-name="dataTab" size="50" />
    </v-tabs>
    <component :is="currentTabComponent" />
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount, onMounted } from "vue";
import HomeTab from "@/components/HomeTab.vue";
import DataTab from "@/components/DataTab.vue";
import { useUserStore, useUserMetaStore } from "@/store/userStore";
import { useBPAStore } from "@/store/bpaStore";
import { useAlertStore } from "@/store/alertStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { useLoadingStore } from "@/store/loadingStore";

const { startLoading, stopLoading, setMsg } = useLoadingStore();
const bpaStore = useBPAStore();
const userStore = useUserStore();
const metaStore = useUserMetaStore();
const activeTab = ref(0);

onMounted(async () => {
  // Note: only alert on awaitable answer directly from fb
  const uid = userStore.getUID;
  if (uid != null) {
    try {
      const alertStore = useAlertStore();

      // Check requesting status
      let requesting = metaStore.isRequesting;

      if (requesting == null) {
        await metaStore.fetchRequestingAction(uid);
        requesting = metaStore.isRequesting;

        if (requesting == false) {
          await bpaStore.initBPA();
        }
      }

      if (metaStore.isRequesting == true) {
        alertStore.alertWarn("Your account is pending approval.");
      } else if (metaStore.isRequesting == false) {
        // Fetch bpa data here
      }

      // Check if verified
      const r = userStore.isVerified;
      if (!r) {
        alertStore.alertWarn(
          "Please click link from account verification email to activate.",
        );
      }

      stopLoading("dataTab");
    } catch (error) {
      console.error("err in onMounted: ", error);
    }
  }
});

onBeforeMount(async () => {
  setMsg("dataTab", "checking account");
  startLoading("dataTab");
});

const tabItems = [
  {
    label: "Home",
    content: "Home Content",
    component: HomeTab,
    authOnly: false,
  },
  {
    label: "Data",
    content: "Data Content",
    component: DataTab,
    authOnly: true,
  },
];

const computedTabItems = computed(() => {
  const activeTabsArr = tabItems.filter(
    (x) => !x.authOnly || (x.authOnly && !metaStore.requesting),
  );
  activeTabsArr.forEach((x) => {
    if (x.label == "Data") {
      stopLoading("dataTab");
    }
  });
  return activeTabsArr;
});

const currentTabComponent = computed(() => {
  const currentTab = computedTabItems.value[activeTab.value];
  return currentTab ? currentTab.component : null;
});
</script>
