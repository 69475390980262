<template>
  <v-card class="mx-auto" color="grey-lighten-3">
    <v-toolbar
      floating
      density="prominent"
      color="#581010"
      :image="selectedImage"
    >
      <template v-slot:image>
        <v-img
          gradient="to bottom left, rgba(249,246,246,.7), rgba(135,9,9,.8)"
        ></v-img>
      </template>
      <slot></slot>
    </v-toolbar>
  </v-card>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";

let selectedImage = ref("");
const images = [
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/1%20-%20Ufa6RYx.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/10%20-%20Tx4Hoal.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/11%20-%20QZu0LzQ.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/12%20-%20ubWhPH0.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/13%20-%20AWC4ZNG.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/14%20-%20B65Iuon.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/15%20-%20JdZWjB8.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/16%20-%20eHwtxvA.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/17%20-%207CUPJNu.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/18%20-%20HpC4INu.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/19%20-%20EncJ1zP.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/2%20-%20qgihbRv.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/3%20-%204hWfa15.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/4%20-%20DBJ5Tfi.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/5%20-%206yVqjO9.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/6%20-%20UehzKIK.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/7%20-%20iKIS662.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/8%20-%20hNv5vQv.jpg",
  "https://storage.googleapis.com/bccrf-dev.appspot.com/Imgur%20Album%20%20Cranberries/9%20-%20MOp6K4Y.jpg",
];

onBeforeMount(() => {
  selectedImage = randomItem(images);
});

const randomItem = (images: any) => {
  return images[Math.floor(Math.random() * images.length)];
};
</script>
