<script setup lang="ts">
import { onMounted, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAlertStore } from "@/store/alertStore";
import { useUserStore } from "@/store/userStore";
import {
  /*
  OK,
  SuccessType,
  ErrorType,
  ERR_JOB_PENDING,
  RESOLVED,
  MatchResult,
  PayloadType,
  StatusType,
  */
  isErrorType,
  isSuccessType,
} from "@/composables/utils/util.composable";
import { ref } from 'vue';
import ResetPassword from "@/components/GoogleLogin/ResetPassword.vue";


const isResetCodeVerified = ref(false);
const verifiedOobCode = ref('');

const userStore = useUserStore();
const alertStore = useAlertStore();
const route = useRoute();
const router = useRouter();
const oobCode = ref('');


const handleResetCode = async (oobCode: string) => {
  try {
    const response = await userStore.verifyPasswordCode(oobCode);
    if (isSuccessType(response.status)) {
      isResetCodeVerified.value = true;
      verifiedOobCode.value = oobCode;
    } else {
      alertStore.snackWarn(response.message);
      router.push({ name: "Login" });
    }
  } catch (e) {
    // Handle error
    console.error("handleResetCode: ", e);
  }
}

const handleVerifyCode = async (code: string): Promise<void> => {

  try {
    const response = await userStore.verifyEmailCode(code);
    const status = response.status;
    if (isErrorType(status)) {
      
      // Warn user about problem fetching verification code
      alertStore.snackWarn(response.message);

      // Push user to login
      router.push({
        name: "Login",
      });

    } else if (isSuccessType(status)) {
      oobCode.value = code;
      const uid = userStore.getUID;
      
      // Get user uid from store and push to homepage
      if (uid != "") {
        alertStore.snackSuccess("Email Verified");
        router.push({
          name: "User",
        });
      }
    }
  } catch (e) {
    console.error("UserMgmt: ", e);
    // TODO: notify you on exceptions
    alertStore.alertWarn(
      "There was a problem verifying your email, the team was notified.",
    );

    // Push user to login
    router.push({
      name: "Login",
    });
  } finally {
    // TODO: stop loading
    // other clean up
  }
};

onBeforeUnmount(() => {
  //  auth.unwatchAuthState();
});

onMounted(() => {
  //const route = useRoute()
  const mode = route.query.mode as string;
  const oobCode = route.query.oobCode as string;
  //const continueUrl = route.query.continueUrl as string
  switch (mode) {
    // https://firebase.google.com/docs/auth/custom-email-handler
    case "verifyEmail":
      handleVerifyCode(oobCode);
      break;
    case "resetPassword":
      handleResetCode(oobCode);
      break;
    default:
      console.log("error");
  }
});
</script>
<template>
  <div>
    <!-- Show progress or verification message -->
    <div v-if="!isResetCodeVerified" class="text-center">
      <v-progress-circular indeterminate model-value="20" size="128" color="primary">
        <v-card-text>Verifying your account</v-card-text>
      </v-progress-circular>
    </div>
    <!-- ResetPassword component is shown if the code is verified -->
    <ResetPassword v-if="isResetCodeVerified" :oobCode="verifiedOobCode"/>
  </div>
</template>

<style scoped>
.v-progress-circular {
  margin: 5rem;
}
</style>
