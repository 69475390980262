<script setup lang="ts">
import { computed } from "vue";
import { useUserStore } from "@/store/userStore";
import { useRouter } from "vue-router";

const userStore = useUserStore();
const router = useRouter();

const handleLogoutClick = async () => {
  await userStore.logout();
  router.push({ name: "Login" });
};

const handleGoHomeClick = async () => {
  router.push({ name: "User" });
};

const computedPhoto = computed({
  // Create elixir icon creator if photoURL is false - store in fs?
  get() {
    if (userStore.getUID != null) {
      if (userStore.photoUrl != null) {
        return userStore.photoUrl.toString();
      }
    }
    return false;
  },
  set() {},
});
</script>

<template>
  <v-menu offset-y bottom origin="center center" transition="scale-transition">
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-avatar>
          <img v-if="computedPhoto" :src="computedPhoto" />
          <v-icon size="small" v-else> mdi-account-circle </v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="handleGoHomeClick">
        <v-list-item-title>
          Home
          <v-btn flat icon>
            <v-icon flat size="small" icon="mdi-home" />
          </v-btn>
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleLogoutClick">
        <v-list-item-title>
          Logout
          <v-btn flat icon>
            <v-icon flat size="small" icon="mdi-logout" />
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
