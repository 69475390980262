import { defineStore } from "pinia";

interface LoadingState {
  loadingIndicators: Record<string, boolean>;
  msg: string;
}

// TODO: check network connection -> disconnect (wifi off)
export const useLoadingStore = defineStore("indicator", {
  // Set time out -> error after 8 seconds
  state: (): LoadingState => ({
    loadingIndicators: {},
    msg: "loading",
  }),
  getters: {
    isLoading: (state) => (instanceName: string) => {
      return state.loadingIndicators[instanceName] || false;
    },
  },
  actions: {
    setMsg(_instanceName: string, msg: string) {
      // TODO: messages not tied to instances
      this.msg = msg;
    },
    startLoading(instanceName: string) {
      this.loadingIndicators[instanceName] = true;
    },
    stopLoading(instanceName: string) {
      this.loadingIndicators[instanceName] = false;
    },
  },
});
