<template>
  <div class="ma-5 pa-5">
    <v-container fluid>
      <v-card class="pa-md-8" mx-lg-auto color="white">
        <v-card-title class="favs-blue table-header-font" elevation="2">
          Barrels Per Acre Summary Table
        </v-card-title>
        <VarietySelector />
        <v-data-table
          :headers="headers"
          :items="computedBPA"
          :loading="computedLoading"
          loading-text="loading..."
          class="elevation-1 table-font"
          density="compact"
          hover
        >
          <template
            v-slot:header.date_collected="{ column, getSortIcon, toggleSort }"
          >
            <div align="left">
              <v-hover v-slot="{ isHovering, props }">
                <td v-bind="props" :class="{ hovered: isHovering }">
                  <span>{{ column.title }}</span>
                  <v-icon
                    :style="{ color: 'lightgrey' }"
                    :class="{ invisible: !isHovering }"
                    :icon="getSortIcon(column)"
                    @click="toggleSort(column)"
                    class="icon-class"
                  />
                </td>
              </v-hover>
            </div>
          </template>
          <template v-slot:header.yield="{ column }">
            <div align="center">
              <td>
                <span>{{ column.title }}</span>
              </td>
            </div>
          </template>
          <template v-slot:header.variety="{ column }">
            <div align="center">
              <td>
                <span>{{ column.title }}</span>
              </td>
            </div>
          </template>
          <template
            v-slot:header.total_barrels_avg="{
              column,
              getSortIcon,
              toggleSort,
            }"
          >
            <div align="right">
              <v-hover v-slot="{ isHovering, props }">
                <td v-bind="props" :class="{ hovered: isHovering }">
                  <v-icon
                    :style="{ color: 'lightgrey' }"
                    :class="{ invisible: !isHovering }"
                    :icon="getSortIcon(column)"
                    @click="toggleSort(column)"
                  />
                  <span>{{ column.title }}</span>
                </td>
              </v-hover>
            </div>
          </template>
          <template
            v-slot:header.sdc3_barrels_avg="{ column, getSortIcon, toggleSort }"
          >
            <div align="right">
              <v-hover v-slot="{ isHovering, props }">
                <td v-bind="props" :class="{ hovered: isHovering }">
                  <v-icon
                    :style="{ color: 'lightgrey' }"
                    :class="{ invisible: !isHovering }"
                    :icon="getSortIcon(column)"
                    @click="toggleSort(column)"
                  />
                  <span>{{ column.title }}</span>
                </td>
              </v-hover>
            </div>
          </template>
          <template v-slot:item.variety="{ item }">
            <div align="center">
              {{ item.variety }}
            </div>
          </template>
          <template v-slot:item.total_barrels_avg="{ item }">
            <div align="right">
              {{ item.total_barrels_avg }}
            </div>
          </template>
          <template v-slot:item.sdc3_barrels_avg="{ item }">
            <div align="right">
              <v-chip
                :color="getColor(item)"
                dark
                class="text-uppercase"
                size="small"
                label
              >
                {{ item.sdc3_barrels_avg }}
              </v-chip>
            </div>
          </template>
          <template v-slot:body.append>
            <tr>
              <td :colspan="2">
                <span class="favs-blue" style="font-weight: bold">
                  Avg. Totals
                </span>
              </td>
              <td align="right">
                <v-chip
                  label
                  class="pa-2 ma-1"
                  color="#002f40"
                  text-color="white"
                >
                  {{ getTotals.total }}
                </v-chip>
              </td>
              <td align="right">
                <v-chip
                  label
                  class="pa-2 ma-1"
                  color="#002f40"
                  text-color="white"
                >
                  {{ getTotals.sdc3 }}
                </v-chip>
              </td>
            </tr>
          </template>
          <!--Removes footer-->
          <template #bottom></template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useBPAStore } from "@/store/bpaStore";
import { storeToRefs } from "pinia";
import VarietySelector from "./VarietySelector.vue";
import colors from "vuetify/util/colors";
import { computed } from "vue";

const bpa = useBPAStore();
// TODO: double check that these need to be refs
const { getLoadedBPADocs, getTotals } = storeToRefs(bpa);

let headers = [
  {
    title: "Date Collected",
    key: "date_collected",
    sortable: true,
    class: "favs-blue",
  },
  {
    title: "Variety",
    key: "variety",
    sortable: false,
  },
  {
    title: "Yield",
    key: "yield",
    children: [
      {
        title: "Total Barrels Average",
        key: "total_barrels_avg",
        sortable: true,
      },
      {
        title: "SDC3 Barrels Average",
        key: "sdc3_barrels_avg",
        sortable: true,
      },
    ],
  },
];

const computedBPA = computed(() => {
  let items = getLoadedBPADocs;
  let formatted = items.value.map((doc) => {
    // Check if total_barrels_avg is a number and round it, otherwise set to "no data"
    doc.bpaDoc.total_barrels_avg = !isNaN(doc.bpaDoc.total_barrels_avg)
      ? parseFloat(doc.bpaDoc.total_barrels_avg).toFixed(1)
      : "no data";

    // Check if sdc3_barrels_avg is a number and round it, otherwise set to "no data"
    doc.bpaDoc.sdc3_barrels_avg = !isNaN(doc.bpaDoc.sdc3_barrels_avg)
      ? parseFloat(doc.bpaDoc.sdc3_barrels_avg).toFixed(1)
      : "no data";

    return doc.bpaDoc;
  });

  // Append totals
  return formatted;
});

const computedLoading = computed({
  get() {
    // return this.getSTBPALoading
    //return false;
    return bpa.getLoading;
  },
  set() {
    // this.$store.dispatch('SetSTBALoadingAction', status)
  },
});

const getColor = (bpaDetails: any) => {
  if (bpaDetails.sdc3_barrels_avg == "no data") {
    return colors.red.darken1;
  }

  // return colors.green.lighten3;

  /*
  switch(color) {
      case 5:
          return colors.red.darken1
      case 4:
          return colors.red.lighten1
      case 3:
          return colors.red.lighten2
      case 2:
          return colors.red.lighten3
      case 1:
          return colors.red.lighten4
  }
  */
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap");
.v-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-font {
  font-family: "IBM Plex Sans";
}
.table-header-font {
  font-family: "Optima";
  font-weight: normal;
}
.pluto-grey {
  color: #f3f6f9;
}
.pluto-blue {
  color: #dbecee;
}
.pluto-red {
  color: #812d2a;
}
.favs-blue {
  color: #002f40;
}
.custom-header-cell {
  display: flex;
  align-items: center;
}
.icon-class {
  margin-left: 5px; /* Adjust the margin as needed to separate the icon and text */
  margin-right: 5px;
}
.invisible {
  visibility: hidden;
}
.my-border {
  border-left: 5px solid red;
}
.custom-table th,
.custom-table td {
  border-right: 1px solid #ccc; /* Add a right border to each table cell */
}
</style>
