<script setup lang="ts">
import { defineProps } from "vue";
import { useAlertStore } from "@/store/alertStore";

const props = defineProps({
  target: String,
});

const alertStore = useAlertStore();
</script>

<template>
  <v-snackbar
    v-model="alertStore.snackStatus"
    :color="alertStore.snackColor"
    :delay="alertStore.snackDelayInMilliseconds"
  >
    {{ alertStore.snackMsg }}
  </v-snackbar>
  <v-alert
    v-if="
      alertStore.alertStatus &&
      (!alertStore.alertTarget || alertStore.alertTarget === props.target)
    "
    v-model="alertStore.alertStatus"
    :title="alertStore.alertTitle"
    :type="alertStore.alertType"
    dense
    closable
    variant="tonal"
  >
    {{ alertStore.alertMsg }}
  </v-alert>
</template>
