import { User, onAuthStateChanged } from "firebase/auth";
import { ref } from "vue";
import { watch } from "vue";
import { defineStore } from "pinia";
import { userPiniaActions } from "@/api/piniaAction.api";
import {
  MatchResult,
  isErrorType,
  // PayloadType,
  isClaimsWrapper,
  isSuccessType,
  StatusType,
  isBoolean,
  isTokenType,
} from "@/composables/utils/util.composable";
import { auth } from "@/services/firebase.service";

const pa = userPiniaActions();
/*
  state: () => ({
    email: ref<string |null>(null),
    uid: ref<string |null>(null),
    // token: ref(""),
    user: <User|null>(null),//ref<User | null>(null), // The raw property is of type User or null
    error: <MatchResult|null>(null),
    emailVerified: ref<boolean | null>(null),
    displayName: ref<string |null>(null),
    photoUrl: ref<string |null>(null),
    authReady: ref<boolean | null>(null),
    token:
    unsubscribeAuth: null as (() => void) | null,  // Function to unsubscribe from auth changes
    
  }),
*/
interface AuthState {
  email: string | null;
  uid: string | null;
  user: User | null;
  error: MatchResult | null;
  emailVerified: boolean | null;
  displayName: string | null;
  photoUrl: string | null;
  authReady: boolean | null;
  token: string | null;
  unsubscribeAuth: (() => void) | null;
}

export const useUserStore = defineStore("user", {
  state: (): AuthState => ({
    email: null,
    uid: null,
    user: null,
    error: null,
    emailVerified: null,
    displayName: null,
    photoUrl: null,
    authReady: null,
    token: null,
    unsubscribeAuth: null,
  }),
  getters: {
    isVerified: (state) => {
      return state.emailVerified;
    },
    getEmail: (state) => {
      return state.email;
    },
    getDisplayName: (state) => {
      return state.displayName;
    },
    getToken: (state) => {
      return state.token;
    },
    getUID: (state) => {
      //if (state.uid != null)
      //  return (state.uid);
      //return null
      return state.uid;
    },
    getError: (state) => {
      // JUST RETURN MESSAGE
      if (state.error) {
        return state.error.message;
      }
      return null;
    },
  },
  actions: {
    setError(match: MatchResult) {
      this.error = match;
    },
    setUID(uid: string | null) {
      this.uid = uid;
    },
    setEmailVerified(status: boolean | null) {
      this.emailVerified = status;
    },
    setEmail(email: string | null) {
      this.email = email;
    },
    setDisplayName(name: string | null) {
      this.displayName = name;
    },
    setPhotoUrl(url: string | null) {
      this.photoUrl = url;
    },
    setToken(token: string | null) {
      this.token = token;
    },
    async fetchToken() {
      const match = await pa.getToken();
      if (isErrorType(match.status)) {
        this.setError(match);
      } else {
        if (isSuccessType(match.status)) {
          if (isTokenType(match.payload)) {
            this.setToken(match.payload);
          }
        }
      }
    },
    setLogout() {
      // this.setEmail(null),
      this.setEmail(null),
        this.setUID(null),
        this.setEmailVerified(null),
        this.setDisplayName(null),
        this.setPhotoUrl(null);
      this.setToken(null);

      const meta = useUserMetaStore();
      meta.clearState();
    },
    async resetPasswordEmail(email: string) {
      const response = await pa.sendPasswordResetEmail(email);
      if (isErrorType(response.status)) {
        this.setError(response);
      }
    },
    async verifyPasswordCode(code: string) {
      const match = await pa.verifyPasswordCode(code);
      if (isErrorType(match.status)) {
        this.setError(match);
      } 
      return match;
    },
    async resetPassword(code: string, pw: string) {
      const match = await pa.passwordReset(code, pw);
      if (isErrorType(match.status)) {
        this.setError(match);
      }
      return match;
    },
    // TODO: messageStore?
    async verifyEmailCode(code: string) {
      const response = await pa.verifyEmailCodeTE(code);

      if (isErrorType(response.status)) {
        this.setError(response);
      } else {
        // update store
        const match = await pa.isAuthUserVerified();
        if (isSuccessType(match.status)) {
          if (isBoolean(match.payload)) {
            this.setEmailVerified(match.payload);
          }
        }
      }

      return response;
    },
    async logout() {
      await pa.logout();

      // Note: push routes in vue files only
      // Note: onAuthLoad sets logout properties to store
    },
    async loginUserPass(email: string, pass: string) {
      const response = await pa.loginUserPass(email, pass);
      if (isErrorType(response.status)) {
        this.setError(response);
      }
      // Check Email verified
      // Check requesting
    },
    async loginFederated() {
      // Note onAuth sets user properties
      const response = await pa.googleFederatedlogin();
      if (isErrorType(response.status)) {
        this.setError(response);
      }
      // TODO: check requesting
      // testing - remove this
      //response.message = "fake failure"
      //this.setError(response)
    },
    async registerWithEmailVerification(
      email: string,
      password: string,
    ): Promise<StatusType> {
      const response = await pa.registerWithEmailVerificationTE(
        email,
        password,
      );
      if (isErrorType(response.status)) {
        this.setError(response);
      }
      return response.status;
    },
    listenToAuthChanges() {
      // Ensures we don't set up multiple listeners
      if (this.unsubscribeAuth) {
        return;
      }

      this.unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
        try {
          this.authReady = true;

          if (user) {
            // User is signed in
            this.setUID(user.uid);
            this.setEmail(user.email);
            this.setEmailVerified(user.emailVerified);
            this.setDisplayName(user.displayName);
            this.setPhotoUrl(user.photoURL);
            await this.fetchToken();

            const userMetaStore = useUserMetaStore();
            await userMetaStore.fetchRequestingAction(user.uid);
          } else {
            // User is signed out
            this.setLogout();
          }
        } catch (error) {
          console.error("failure during init: ", error);
        }
      });
    },
    unlistenToAuthChanges() {
      if (this.unsubscribeAuth) {
        this.unsubscribeAuth(); // Call the unsubscribe function
        this.unsubscribeAuth = null; // Clear the reference
      }
    },
    waitForAuthReady() {
      // Return a promise that resolves when authReady becomes true
      return new Promise((resolve) => {
        // console.log(this.authReady)
        if (this.authReady) {
          resolve(this.user);
        } else {
          // Use Vue's watch to react to authReady changes
          const stopWatch = watch(
            () => this.authReady,
            (newVal) => {
              if (newVal) {
                stopWatch(); // Stop watching
                resolve(this.user); // Resolve with the user object
              }
            },
          );
        }
      });
    },
  },
});

export const useUserMetaStore = defineStore("usermeta", {
  state: () => ({
    requesting: ref<boolean | null>(null),
    error: <MatchResult | string | null>null,
  }),
  getters: {
    isRequesting: (state) => {
      return state.requesting;
    },
  },
  actions: {
    clearState() {
      (this.requesting = null), (this.error = null);
    },
    setRequesting(payload: any) {
      if (isClaimsWrapper(payload)) {
        this.requesting = payload.claims.requesting;
      } else {
        this.error = `set called was not a claims object`;
      }
    },
    async fetchRequestingAction(uid: string) {
      try {
        const response = await pa.fetchRequestingW(uid);

        if (isSuccessType(response.status)) {
          if (response.payload != null) {
            this.setRequesting(response.payload);
          } else {
            this.error = "fetchRequestingAction payload was null";
          }
        } else if (isErrorType(response.status)) {
          console.error("fetchRequesitnAciton error: ", response);
          this.error = response;
        } else {
          console.error("fetchRequestingAction other error.");
        }

        return response.status;
      } catch (error) {
        const message = `An unexpected error occurred: ${error}`;
        console.error(message);
        this.error = message;
        return { success: false, data: this.error };
      }
    },
  },
});
