<script setup lang="ts">
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/userStore";
import { useAlertStore } from "@/store/alertStore";
//import  GoogleIcon from "@/assets/web_light_rd_na.svg";

const alertStore = useAlertStore();

const userStore = useUserStore();
const router = useRouter();

const handleLoginWithGoogleClick = async () => {
  await userStore.loginFederated();
  const err = userStore.getError;

  if (err === null) {
    const uid = userStore.getUID;

    if (uid != null) {
      router.push({
        name: "User",
      });
    }
  } else {
    alertStore.alertWarn(`error: ${err}`, "regUserPass");
  }
  // Alert fetched in RegUserPass component
};
</script>

<template>
  <div>
    <v-btn @click="handleLoginWithGoogleClick" block color="#ffffff">
      <v-icon class="google-login-btn"/>
      Sign in with Google
    </v-btn>

  </div>
</template>
<style>
.google-login-btn {
  background-image: url('@/assets/web_light_rd_na.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 50px; /* Adjust based on your icon size */
  padding-top: 20px;
}
.text-cran {
  color: #581010;
}
</style>
