import * as Sentry from "@sentry/browser";
//import * as t from 'io-ts';

import { useUserStore } from "@/store/userStore";
import { User } from "firebase/auth";
import { FBCustomError } from "./error.composable";

export const errorToCloud = (error: FBCustomError) => {
  const userStore = useUserStore();

  Sentry.withScope((scope) => {
    scope.setTag("Account ID", userStore.getUID);
    scope.setTag("Account Email", userStore.getEmail);
    scope.setTag("error.name", error.name);
    scope.setExtra("error.message", error.message);

    // Assuming `originalError` is a complex object, you might want to stringify it or
    // pick specific properties to avoid issues with non-serializable values
    scope.setExtra(
      "error.originalError",
      JSON.stringify(error.originalError, null, 2),
    );

    // Set the source function as a tag for easy filtering
    scope.setTag("error.sourceFunction", error.sourceFunction);

    // Capture the exception with the enriched scope
    Sentry.captureException(error);
  });
};

// App types and constants
export type SuccessType = {
  ok: string;
};

export type ErrorType = {
  oops: string;
};

export interface Claims {
  admin: boolean;
  requesting: boolean;
}

export interface ClaimsWrapper {
  claims: Claims;
}

export interface Payload {
  claims?: Claims;
  years?: YearsData;
}

export type YearsData = {
  years: string[];
};

export type VarietiesData = {
  varieties: string[];
};

export type TokenType = string;

export function isClaimsWrapper(payload: any): payload is ClaimsWrapper {
  return (
    payload !== undefined &&
    payload !== null &&
    typeof payload === "object" &&
    "claims" in payload &&
    typeof payload.claims === "object" && // Additional checks can be added here if needed
    "admin" in payload.claims &&
    typeof payload.claims.admin === "boolean" &&
    "requesting" in payload.claims &&
    (typeof payload.claims.requesting === "boolean" ||
      payload.claims.requesting === null)
  );
}

export function isClaims(payload: any): payload is Claims {
  return (
    payload !== undefined &&
    payload !== null &&
    typeof payload === "object" &&
    "admin" in payload &&
    typeof payload.admin === "boolean" &&
    "requesting" in payload &&
    typeof payload.requesting === "boolean"
  );
}

export function isTokenType(value: any): value is TokenType {
  return typeof value === "string";
}

export function isBoolean(value: any): value is boolean {
  return typeof value === "boolean";
}

export function isVarietiesData(obj: any): obj is VarietiesData {
  return (
    obj !== null &&
    typeof obj === "object" &&
    Array.isArray(obj.varieties) &&
    obj.varieties.every((variety: string) => typeof variety === "string")
  );
}

export function isYearsData(obj: any): obj is YearsData {
  return (
    obj !== null &&
    typeof obj === "object" &&
    Array.isArray(obj.years) &&
    obj.years.every((year: string) => typeof year === "string")
  );
}

export function isErrorType(obj: any): obj is ErrorType {
  return obj && typeof obj.oops === "string";
}

export function isSuccessType(obj: any): obj is SuccessType {
  return obj && typeof obj.ok === "string";
}

export function getUID(payload: PayloadType): string | null {
  if (payload && typeof payload === "object" && "uid" in payload) {
    // Check if 'uid' is actually a string
    return typeof payload.uid === "string" ? payload.uid : null;
  }
  return null;
}

export function isBPAType(obj: any): obj is BPAType {
  return (
    obj !== null &&
    typeof obj === "object" &&
    typeof obj.month === "string" &&
    typeof obj.year === "number" &&
    obj.bpaDoc !== undefined &&
    typeof obj.bpaDoc === "object" &&
    // Since bpaDoc's properties are of type 'any', we only check for their existence
    "sample_count" in obj.bpaDoc &&
    "sdc3_barrels_avg" in obj.bpaDoc &&
    "total_barrels_avg" in obj.bpaDoc &&
    "date_collected" in obj.bpaDoc &&
    "variety" in obj.bpaDoc
  );
}

export function isArrayOfBPAType(obj: any): obj is BPAType[] {
  return Array.isArray(obj) && obj.every(isBPAType);
}

export const formatter = (
  msg: string,
  status: StatusType,
  payload: PayloadType,
): MatchResult => {
  return {
    message: msg,
    status: status,
    payload: payload,
  };
};
export type BPAType = {
  month: string;
  year: number;
  bpaDoc: {
    sample_count: any;
    sdc3_barrels_avg: any;
    total_barrels_avg: any;
    date_collected: any;
    variety: any;
  };
};

export const newBPA = (
  sample_count: any,
  sdc3_barrels_avg: any,
  total_barrels_avg: any,
  date_collected: any,
  variety: any
):BPAType => {
  const bpaDetails = {
    sample_count: sample_count,
    sdc3_barrels_avg: sdc3_barrels_avg,
    total_barrels_avg: total_barrels_avg,
    date_collected: date_collected,
    variety: variety
  }
  
  const date = date_collected;
  const d = new Date(date);
  const yr = d.getFullYear();
  const m = d.toLocaleString("default", {month: "long"});
  return {
    month: m,
    year: yr,
    bpaDoc: bpaDetails
  }
}

export type PayloadType =
  | undefined
  | null
  | TokenType
  | SuccessType
  | User
  | Claims
  | BPAType[]
  | VarietiesData
  | YearsData
  | boolean;
export type StatusType = SuccessType | ErrorType;

// Use this where wrapped functions return void on success
export const OK: SuccessType = { ok: "ok" };
export const ERR_JOB_PENDING: ErrorType = { oops: "oops" };
export const RESOLVED: ErrorType = { oops: "ok" };

// type returned from control api to presentation layer
export type MatchResult = {
  status: StatusType;
  payload: PayloadType; // if success payload returns void -> OK
  message: string;
};

/*
// Example usage?
const status: StatusType = { oops: "An error occurred" };

if (isErrorType(status)) {
  console.log("Status is of type ErrorType");
} else {
  console.log("Status is not of type ErrorType");
}
*/
