import { defineStore } from "pinia";
import { bpaPiniaActions } from "@/api/piniaAction.api";
import {
  isArrayOfBPAType,
  isSuccessType,
  isErrorType,
  MatchResult,
  BPAType,
  isYearsData,
  YearsData,
  isVarietiesData,
  VarietiesData,
} from "@/composables/utils/util.composable";

const api = bpaPiniaActions();

type totalsType = {
  total: string;
  sdc3: string;
};

export const useBPAStore = defineStore("BPA", {
  state: () => ({
    varietySelected: "BG",
    allVarieties: [] as string[],
    allYears: [] as string[],
    loadedBPADocs: [] as BPAType[],
    loadedBPAYears: [] as string[],
    loadedBPAYear: "",
    loadedTotals: {} as totalsType,
    loading: false,
    error: <MatchResult | null>null,
  }),
  getters: {
    getLoading: (state) => {
      return state.loading;
    },
    getVarietySelected: (state) => {
      return state.varietySelected;
    },
    getAllVarieties: (state) => {
      return state.allVarieties;
    },
    getLoadedBPADocs: (state) => {
      return state.loadedBPADocs;
    },
    getTotals: (state) => {
      return state.loadedTotals;
    },
  },
  actions: {
    setError(match: MatchResult) {
      this.error = match;
      // pinia won't be available in prod
      console.error(this.error);
    },
    setLoading(status: boolean) {
      this.loading = status;
    },
    setVarietySelected(variety: string) {
      this.varietySelected = variety;
    },
    setYears(yearsObj: YearsData) {
      this.allYears = yearsObj.years;
    },
    setVarieties(varietiesObj: VarietiesData) {
      this.allVarieties = varietiesObj.varieties;
    },
    calculateSDC3Totals() {
      let docs = this.loadedBPADocs;
      let totals: string[] = [];
      let sdc3s: string[] = [];

      docs.forEach((row) => {
        if (!isNaN(row.bpaDoc.sdc3_barrels_avg)) {
          sdc3s.push(row.bpaDoc.sdc3_barrels_avg);
        }
        if (!isNaN(row.bpaDoc.total_barrels_avg)) {
          // changed totals to total
          totals.push(row.bpaDoc.total_barrels_avg);
        }
      });
      let totalsAvg = (totals: string[]) =>
        totals.reduce((acc, val) => acc + parseInt(val), 0) / totals.length;
      let totalsObj: totalsType = {
        total: totalsAvg(totals).toFixed(1), // Math.round(totalsAvg(totals)),
        sdc3: totalsAvg(sdc3s).toFixed(1),
      };
      this.loadedTotals = totalsObj;
    },
    async fetchDocsByVariety(variety: string) {
      this.setLoading(true);
      const bpaDocMatch = await api.fetchByVariety(variety);
      //console.log("bpaMatch", bpaDocMatch);
      if (isSuccessType(bpaDocMatch.status)) {
        if (isArrayOfBPAType(bpaDocMatch.payload)) {
          this.loadedBPADocs = bpaDocMatch.payload;
        }
      } else if (isErrorType(bpaDocMatch.status)) {
        this.setError(bpaDocMatch);
        console.error("Error fetchingDocsByVariety");
      }
      this.setLoading(false);
    },
    async fetchYears() {
      const match = await api.fetchAllYears();

      if (isSuccessType(match.status)) {
        if (isYearsData(match.payload)) {
          this.setYears(match.payload);
        }
      } else if (isErrorType(match.status)) {
        this.setError(match);
      }
    },
    async fetchVarieties() {
      const match = await api.fetchAllVarieties();

      if (isSuccessType(match.status)) {
        if (isVarietiesData(match.payload)) {
          this.setVarieties(match.payload);
        }
      }
    },
    async updateOnSelect(variety: string) {
      await this.fetchDocsByVariety(variety);
      this.calculateSDC3Totals();
    },
    async initBPA() {
      /*
      let defaults = {
        'variety': 'BG',
        'year': '2018',
        'month': 'September'
      }
      */
      // TODO dont put config here
      this.fetchVarieties();
      this.fetchYears();
      await this.fetchDocsByVariety("BG"); // '2017', 'September'); ("2017-09-20"); //('2018-09-25'),
      //await this.setBPAYearOptions();
      //this.setBPAYear("2017");
      this.calculateSDC3Totals();
    },
  },
});
