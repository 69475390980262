<template>
  <v-container style="height: 20px"></v-container>
  <div class="welcome-title">Welcome to BCCRF.</div>
  <div class="about-us">
    <p>
      The <strong class="bold-blue">BC Cranberry Research Farm</strong> has been
      tasked with investigating and demonstrating how cranberries are grown
      effectively and efficiently in our ever-changing West Coast environment.
      New cranberry cultivars are essential for the British Columbia Cranberry
      Industry as it moves forward in the coming decades. BC cranberry farmers
      will be enabled to continually improve and grow this unique and important
      crop by observing the characteristics of the new cranberry varieties and
      incorporating innovative cultural practices, IPM, crop protection and
      water/nutrient management as demonstrated at the BC Cranberry Research
      Farm. The
      <span class="bold-blue">BCCRF Varietal Trial</span> demonstration
      currently is based on the selections from the Rutgers University™
      Breading Program in New Jersey and Valley Corporation ™ Cranberry Farm in
      Wisconsin, as well as the typical BC cultivar Stevens sourced from local
      farms as well as genetic true type stevens.
    </p>
  </div>
  <div class="disclaimer-statement">
    <span class="bold-blue">Disclaimer</span> Please note, the information
    provided in the following pages is not meant as an endorsement or
    recommendation. This is data collected in a generally accepted randomized
    manor, three collection squares (900 cm2 – 1ft2), arbitrarily placed
    throughout the individual varietal plots. Fruit samples are coded, tabulated
    and the data is averaged based on that specific collection date. Growing
    conditions at the BCCRF are unique as they are in every farm. The Varietal
    Data should only be used for reference purpose as it provides a snapshot
    into a specific growing system at a specific time and date.
  </div>
</template>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
.welcome-title {
  font-family: "Optima";
  color: #002f40;
  font-size: 1.5em;
  margin: 30px;
}
.about-us {
  font-family: "Optima";
  color: grey;
  font-size: 1.2em;
  margin: 30px;
  text-indent: 2em;
}
.disclaimer-statement {
  color: grey;
  font-size: 1.5;
  margin: 30px;
  text-indent: 2em;
}
.bold-blue {
  color: #002f40;
}
</style>
